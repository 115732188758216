import styled, { css, keyframes } from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const fadeInBlur = keyframes`
  0% {
    /* display: none; */
    filter: blur(0px);
    transform: scale(1.1);

  }
  100% {
    filter: blur(10px);
    transform: scale(1.1);

  }
`

export const buzzOut = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

// export const menuFadeIn = keyframes`

//   0% {
//       opacity: 0;
//       transform: translateY(-100%);
//       /* position: absolute; */

//   }

//   100% {
//       /* display: fixed; */
//       opacity: 1;
//       transform: translateY(0);
//       /* position: fixed; */

//   }
// `

// export const menuFadeOut = keyframes`
//   0% {
//       /* display: fixed; */
//       /* opacity: 1;
//       transform: translateY(0); */
//       /* position: fixed; */
//       opacity: 1;
//       transform: translateY(0);
//   }

//   100% {
//       /* display: fixed; */
//       opacity: 0;
//       transform: translateY(-100%);
//       /* position: fixed; */
//   }
// `

// export const imageHover = keyframes`
//   0% {
//     filter: none;
//   }

//   100% {
//     filter: grayscale(100%) brightness(40%) sepia(100%)
//               hue-rotate(-68deg) saturate(600%) contrast(1);
//   }
// `

// export const imageHoverOut = keyframes`
//   0% {
//     filter: grayscale(100%) brightness(40%) sepia(100%)
//               hue-rotate(-68deg) saturate(600%) contrast(1);
//   }

//   100% {
//     filter: none;
//   }
// `
