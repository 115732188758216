import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

const SEO = ({
  title,
  socialTitle,
  titleTemplate,
  description,
  socialDescription,
  image,
  pathname,
  isArticle,
}) => {
  const {
    site: {
      siteMetadata: {
        defaultTitleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        siteName,
      },
    },
  } = useStaticQuery(query)

  const seo = {
    title,
    socialTitle: socialTitle || title,
    description: description || defaultDescription,
    socialDescription: socialDescription || description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname || '/'}`,
    titleTemplate: titleTemplate || defaultTitleTemplate,
    siteName,
  }

  seo.socialTitle = `${seo.socialTitle} — Forbes`

  // console.log('seo', seo)

  return (
    <>
      <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
        <html lang="cs" />

        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        {/* FB open graph */}
        {seo.url && <meta property="og:url" content={seo.url} />}
        {isArticle
          ? <meta property="og:type" content="article" />
          : <meta property="og:type" content="website" />}
        <meta property="og:title" content={seo.socialTitle} />
        <meta property="og:site_name" content={seo.siteName} />
        <meta property="og:description" content={seo.socialDescription} />
        {seo.image && <meta property="og:image" content={seo.image} />}

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
              )} */}
        <meta name="twitter:title" content={seo.socialTitle} />
        <meta name="twitter:description" content={seo.socialDescription} />
        {seo.image && <meta name="twitter:image" content={seo.image} />}

        {/* make it nice */}
        <meta name="theme-color" content="#31553c" />
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isArticle: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  isArticle: false,
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        siteName
      }
    }
  }
`
