import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
// import { BgImage } from 'gbimage-bridge'
import { convertToBgImage } from 'gbimage-bridge'

import BackgroundImage from 'gatsby-background-image'
import { up, colors } from '../lib/styles'
import { fadeInBlur } from '../lib/animations'
import RH from './RH'

// const bgImages = {
//   'blurred-bg': require('../data/images/blurred-bg.jpg').default,
// }

export const Page = styled.div`
  ${({
    theme: { sizes, colors, pageSidePadding, mobilePageSidePadding },
    bg,
    bgImage,
  }) => css`
    width: 100%;
    height: 100%;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    /* you can define bg by multiple options */
    background: ${colors[bg] || bg};

    /* global page padding here! */
    padding: 0 ${mobilePageSidePadding};
    ${up('mobile')} {
      padding: 0 ${pageSidePadding};
    }
  `}
`

export const PageContent = styled.div`
  ${({ height, maxWidth, theme: { sizes }, position }) => css`
    width: 100%;
    position: ${position};
    max-width: ${maxWidth || `${sizes.pageWidth}px`};
    display: flex;
    flex-direction: column;
    align-content: stretch;
    /* height: ${height}; */
  `}
`

const StyledBackgroundImage = styled(BackgroundImage)`
  ${({
    blurred,
    theme: { colors },
    blurTransition,
    minHeight,
    isMobile,
  }) => css`
    overflow: hidden;
    background-color: ${colors.cyan};
    min-height: ${minHeight};
    flex-grow: 1;

    /* make it fixed */
    position: fixed !important;
    width: 100%;
    top: 36px;
    left: 0;
    /* z-index: -1; */

    &:after,
    &:before {
      background-color: ${colors.cyan};
      background-repeat: repeat;
      background-size: ${isMobile ? 480 : 1440}px;
      background-position: top left;
      /* width: 120% !important;
      height: 120% !important;
      left: -10% !important ;
      top: -10% !important; */
      transform: scale(1.1);
      top: 36px !important;

      ${blurred &&
      !blurTransition &&
      css`
        filter: blur(10px);
      `}

      ${blurred &&
      blurTransition &&
      css`
        animation: ${fadeInBlur} 600ms ease forwards;
      `}
    }
  `}
`

export const PageWithBgImage = ({ children, blurred, blurTransition }) => {
  const { bg, bgMobile } = useStaticQuery(
    graphql`
      query {
        bg: file(relativePath: { eq: "images/bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1440
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 85
            )
          }
        }
        bgMobile: file(relativePath: { eq: "images/bg-mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 480
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 85
            )
          }
        }
      }
    `,
  )

  // console.log('bg, bgMobile ', bg, bgMobile)
  // console.log('get, bgMobile ', getImage(bgMobile))

  // const sources = [
  //   getImage(bgMobile),
  //   {
  //     image: getImage(bg),
  //     media: '(min-width: 480px)',
  //   },
  // ]

  const convertedDesktop = convertToBgImage(getImage(bg))
  const convertedMobile = convertToBgImage(getImage(bgMobile))

  // console.log('converted', convertedDesktop)

  return (
    <>
      <RH desktop bp={480}>
        <StyledBackgroundImage
          {...convertedDesktop}
          style={{
            backgroundRepeat: 'repeat',
            backgroundSize: '1440px',
            backgroundPosition: 'top left',
            top: '36px',
          }}
          backgroundColor={colors.cyan}
          blurred={blurred}
          blurTransition={blurTransition}
          minHeight="100vh"
        />
      </RH>

      <RH mobile bp={480}>
        <StyledBackgroundImage
          {...convertedMobile}
          style={{
            backgroundRepeat: 'repeat',
            backgroundSize: '480px',
            backgroundPosition: 'top left',
            top: '36px',
          }}
          backgroundColor={colors.cyan}
          blurred={blurred}
          blurTransition={blurTransition}
          minHeight="100%"
          isMobile
        />
      </RH>
      <Page>{children}</Page>
    </>
  )
}
